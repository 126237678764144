import { Head } from "@inertiajs/react";

const FrontSeoMetaTags = ({ seo }) => {
    const isServer = typeof window === 'undefined'
    if (seo) {
        const robots = `${seo.noindex ? 'noindex' : ''}${seo.nofollow && seo.noindex ? ', ' : ''}${seo.nofollow ? 'nofollow' : ''}`;

        return (
            <Head>
                {/* =================== [Description] =================== */}
                {seo.description && (
                    <meta name="description" content={seo.description} />
                )}

                {/* =================== [OG] =================== */}
                {seo.og_title && (
                    <meta property="og:title" content={seo.og_title} />
                )}
                {seo.og_description && (
                    <meta property="og:description" content={seo.og_description} />
                )}
                <meta property="og:type" content="website" />
                <meta property="og:url" content={`https://warehousemarket.pl`} />
                {seo.og_image && (
                    <meta property="og:image" content={`/storage/${seo.og_image}`} />
                )}
                {seo.created_at && (
                    <meta property="og:published_time" content={seo.created_at} />
                )}

                {/* =================== [Twitter] =================== */}
                {seo.title && (
                    <meta name="twitter:title" content={seo.title} />
                )}
                {seo.title && (
                    <meta name="twitter:card" content={seo.title} />
                )}
                {seo.twitter_text && (
                    <meta name="twitter:description" content={seo.twitter_text} />
                )}
                {seo.og_image && (
                    <meta name="twitter:image" content={`/storage/${seo.og_image}`} />
                )}

                {/* =================== [Canonical] =================== */}
                {!isServer &&
                    <link rel="canonical" href={`${window.location.protocol}//${window.location.hostname}${window.location.pathname}`} />
                }

                {/* =================== [Robots] =================== */}
                {robots && (
                    <meta name="robots" content={robots}/>
                )}

                {/* =================== [Title] =================== */}
                {seo.title && (
                    <title>{seo.title}</title>
                )}
            </Head>
        );
    }
};

export default FrontSeoMetaTags;
